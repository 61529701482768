{
  "name": "kiosk",
  "version": "6.0.8",
  "scripts": {
    "ng": "ng",
    "start": "ng serve",
    "build": "ng build --configuration production --aot=true --base-href /kiosk/ && npm run sentry:sourcemaps",
    "build-dev": "ng build --configuration dev --aot=true --base-href /kiosk/",
    "test": "ng test",
    "lint": "ng lint --fix",
    "e2e": "ng e2e",
    "prebuild": "npm --no-git-tag-version version patch",
    "update": "ng update @angular/core@18 @angular/cli@18",
    "cf": "ng generate @angular/core:control-flow",
    "standlonw": "ng generate @angular/core:standalone",
    "state-migration": "ng update @ngrx/store@18",
    "sentry:sourcemaps": "sentry-cli sourcemaps inject --org zendynamix --project iotzen-kiosk ./dist && sentry-cli sourcemaps upload --org zendynamix --project iotzen-kiosk ./dist"
  },
  "private": true,
  "dependencies": {
    "@angular/animations": "^18.1.4",
    "@angular/cdk": "^18.1.4",
    "@angular/common": "^18.1.4",
    "@angular/compiler": "^18.1.4",
    "@angular/core": "^18.1.4",
    "@angular/forms": "^18.1.4",
    "@angular/platform-browser": "^18.1.4",
    "@angular/platform-browser-dynamic": "^18.1.4",
    "@angular/router": "^18.1.4",
    "@angular/service-worker": "^18.1.4",
    "@ngrx/effects": "^18.0.2",
    "@ngrx/store": "^18.0.2",
    "@ngx-translate/core": "^15.0.0",
    "@sentry/angular": "^8.25.0",
    "@zdIOTPlatform/ng-iot-printers": "^1.2.4",
    "@zdIOTPlatform/zen-tax": "^1.0.16",
    "firebase": "^10.12.5",
    "primeicons": "^7.0.0",
    "primeng": "^17.18.8",
    "razorpay": "^2.9.4",
    "rxjs": "~7.8.1",
    "simple-keyboard": "^3.7.101",
    "simple-keyboard-layouts": "^3.4.15",
    "socket.io-client": "^4.7.5",
    "tslib": "^2.6.3",
    "zone.js": "~0.14.10"
  },
  "devDependencies": {
    "@angular-devkit/build-angular": "^18.1.4",
    "@angular/cli": "^18.1.4",
    "@angular/compiler-cli": "^18.1.4",
    "@ngrx/store-devtools": "^18.0.2",
    "@sentry/cli": "^2.33.1",
    "@types/jasmine": "~5.1.4",
    "@types/node": "^20.14.11",
    "@types/w3c-web-usb": "^1.0.10",
    "angular-eslint": "18.0.1",
    "codelyzer": "^6.0.2",
    "eslint": "^9.5.0",
    "jasmine-core": "~5.1.1",
    "jasmine-spec-reporter": "~7.0.0",
    "karma": "~6.4.1",
    "karma-chrome-launcher": "~3.1.1",
    "karma-coverage-istanbul-reporter": "~2.1.0",
    "karma-jasmine": "~3.0.1",
    "karma-jasmine-html-reporter": "^1.4.2",
    "protractor": "~7.0.0",
    "ts-node": "~10.9.2",
    "tslint": "~6.1.3",
    "typescript": "~5.4.5",
    "typescript-eslint": "8.0.0-alpha.20"
  }
}

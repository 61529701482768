const p = require('../../package.json');
export const environment = {
  production: true,
  pollPrinterStatusApi: true,
  config: 'production',
  BASE_URL: 'https://api.iotzen.app/',
  FILTERS_URL: 'api/assetGroup/?assetTypes=product',
  logoURL: 'assets/images/iot-zen2.png',
  wallURL: 'assets/images/iot-zen2.png',
  appVersion: p.version,
  appName: p.name,
  sentryDns: 'https://1101ce07bd0e2ebb3527b868c141582b@o1302573.ingest.sentry.io/4505799633534976'
};
